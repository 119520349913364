<template>
  <div class="container-lg" >
    <div class="row m-0">
      <div class="col-12 p-0">
        <PageTitle :title="title"/>
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4">
                <span v-if="message" class="page_heading">{{ message }}</span>
                <div v-else class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
            <div class="row m-0 justify-content-center" v-if="status === 'Success'">
              <div class="col-auto">
              <Button color="green" btnText="Download Receipt" icon="arrow" @buttonClicked="downloadReceipt" class="text-center mb-1" :isLoading="isLoading">
                <IconArrowDown color="gold" />
              </Button>
              </div>
            </div>
          </div>
        </div>
        <TheText v-if="status === 'Success'" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowDown: defineAsyncComponent(() => import('@/components/icons/IconArrowDown.vue')),
    TheText: defineAsyncComponent(() => import('./TheText.vue'))
  },
  name: 'Payment Complete Layout',
  props: ['message', 'status', 'title', 'isLoading'],
  emits: ['downloadReceipt'],
  methods: {
    downloadReceipt () {
      this.$emit('downloadReceipt')
    }
  }
}
</script>
